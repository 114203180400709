import React, { useState } from 'react'
import queryString from 'query-string'
import { l } from '../../../lib/locale'

import { PageLayout, GiftListSearchBlock, GiftListItemList, GiftListBlockService } from '../../../components'

const GiftListBuyIndexTemplate = ({ pageContext }) => {
  const { lang, page } = pageContext

  let initialSelected = null
  let id_giftlist_product = null
  if (typeof window !== 'undefined') {
    const qs = queryString.parse(window.location.search)
    initialSelected = qs.id ? qs.id : null
    id_giftlist_product = qs.item ? qs.item : null
  }

  const [selected, setSelected] = useState(initialSelected)

  return (
    <PageLayout lang={lang} switcher={page} className={selected ? '' : 'giftlist account'}>
      {selected ? (
        <GiftListItemList lang={lang} id_giftlist={+selected} id_giftlist_product={+id_giftlist_product} />
      ) : (
        <>
          <div className="giftlist-hero">
            <h1>{l('giftlist-buy-title', lang)}</h1>
          </div>

          <GiftListSearchBlock lang={lang} setSelected={setSelected} />
          <GiftListBlockService lang={lang} />
        </>
      )}
    </PageLayout>
  )
}

export default GiftListBuyIndexTemplate
